<template>
  <section class="section__container">
    <div class="section__container_background">
      <v-img
        :cover="true"
        max-width="100vw"
        max-height="90%"
        src="/backgrounds/page_quemsomos_internationalis.png"
        alt=""
      />
      <GoBack />
    </div>
    <div class="container position--relative">
      <Breadcrumb class="sobre_section_bc-color">
        <template v-slot:breadcrumbLinks>
          <router-link
            tag="p"
            :to="{ name: link.href }"
            v-for="(link, idx) in breadcrumbItems"
            :key="idx"
          >
            {{ link.text }}
          </router-link>
        </template>
      </Breadcrumb>
      <v-row>
        <v-col sm="12" md="7">
          <Card
            title="A Cáritas Internacional"
            borderColor="#C92F05"
            titleColor="#C92F05"
            backgroundColor="#F9F7ED"
          >
            <template v-slot:conteudo>
              <p>
                A Cáritas é um organismo da Igreja Católica Apostólica Romana
                presente em 200 países e territórios, na forma da rede Cáritas
                Internationalis, sediada em Roma, no Vaticano. Esta rede está
                subdividida em 7 regiões: América Latina e Caribe; África;
                Europa; Oceania; Ásia; América do Norte; e Oriente Médio e Norte
                da África (MONA).
              </p>
              <p>
                A história da Cáritas inicia-se com Padre Lorenz Werthmann, que
                institui em 1895 o Comitê Cáritas na cidade de Friburgo, na
                Alemanha, abrindo caminho para fundar no dia 09 de novembro de
                1897 a associação de caridade para a Alemanha católica na cidade
                de Colônia. Tal associação dedicou-se a fornecer auxílio aos
                necessitados e pontuais ações de formação de jovens. Durante a
                Primeira Guerra Mundial, a Cáritas recupera soldados
                prisioneiros sem distinção de nacionalidade e ideologias e
                oferece proteção às crianças órfãs da guerra. Em 1916, os bispos
                da Alemanha oficialmente fizeram da Associação Cáritas Alemã o
                braço do serviço social da Igreja Católica, assegurando o
                financiamento da Associação, promovendo assim uma sólida base
                organizacional. A partir de 1924, com o Congresso Eucarístico
                Mundial, iniciam-se os trabalhos internacionais, contando com
                representação de 22 países.
              </p>
              <p>
                Em 1947, a Cáritas Internacional é reconhecida pelo Vaticano
                como organização de assistência de âmbito internacional e passa
                a receber apoio da ONU. Durante os 15 anos seguintes, a Cáritas
                se expandiu nas regiões católicas e ampliou sua atuação junto às
                populações carentes no mundo. No final da década de 1950, pela
                primeira vez, a Associação Cáritas Alemã expandiu seu trabalho
                tradicional para fornecer ajuda internacional de emergência e
                apoio humanitário em situações de desastres. Em 12 de novembro
                de 1956, é instituída a Cáritas Brasileira, tendo Dom Helder
                Câmara como pivô de sua articulação.
              </p>
            </template>

            <template v-slot:footerImage>
              <div class="card__footer_img mt-3">
                <img
                  width="50%"
                  src="/logos/logo-caritas-internationalis.png"
                  alt=""
                />
              </div>
            </template>
          </Card>
        </v-col>
      </v-row>
    </div>
    <div class="container">
      <v-row>
        <v-col sm="12" md="7" class="card__footer_btn">
          <div
            @click="navigateTo('sobre_caritas_al')"
            class="white--text pa-3 nav__button_intern"
          >
            Conhecer "Cáritas da América Latina e Caribe"
          </div>
        </v-col>
      </v-row>
    </div>
  </section>
</template>

<script>
import Card from "@/components/Card.vue";
import Breadcrumb from "@/layout/Breadcrumb.vue";
import GoBack from "@/components/GoBack.vue";

export default {
  components: {
    Card,
    Breadcrumb,
    GoBack,
  },

  data() {
    return {
      breadcrumbItems: [
        {
          text: "< Quem somos /",
          href: "cards",
        },

        {
          text: "Cáritas Regional Minas Gerais / ",
          href: "sobre_caritas_regional",
        },
        {
          text: "Cáritas Internacional",
          href: "sobre_caritas_internacional",
        },
      ],
    };
  },

  methods: {
    navigateTo(route) {
      return this.$router.push({ name: route });
    },
  },
};
</script>

<style scoped>
.section__container {
  height: 60%;
  width: 100%;
  position: relative;
}

.section__container_background {
  position: fixed;
  top: 0;
}

@media (max-width: 600px) {
  .section__container_background {
    position: relative;
  }
}

.position--relative {
  position: relative;
}

.card__footer_img {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.card__footer_img img {
  width: 40%;
}

.card__footer_btn {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  color: white;
}
</style>
